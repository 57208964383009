// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 
   // local
  // baseUrl: 'https://localhost:5001/',
  // loadpi: 'https://staging-afcs-ws.alleasy.com.ph/api/partners/inquiry?refno=',
 
  // dev k8s
  baseUrl: 'https://legacy.alleasy.dev/giftcard-api/',
  
};